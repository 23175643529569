import {Form} from "react-final-form";
import InputField from "./InputField";
import CheckboxField from "./CheckboxField";
import RadioField from "./RadioField";
import Currency from "./Currency";
import {instance} from "./axio-cnf";
import {useState} from "react";


const FormPage = () => {

    let [loading, setLoading] = useState(false)
    let [done, setDone] = useState(null)

    const save = (values) => {
        setLoading(true)
        instance.post("/api/kdi", values)
        .then(response => {
            setLoading(false)
            setDone(true)
        }).catch(error => {
            setLoading(false)
        })
    }

    const tableinfouser = () => {
        return (
            <div className={"basis-full"}>
                <table className={"w-full border border-sky-500"}>
                    <thead>
                    <tr>
                        <th colSpan={2} className={"text-left text-white pt-2 pb-2 pl-2"}
                            style={{background: "#027fb2"}}>Objednávka služeb
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className={"bg-gray-100"}>
                        <td className={"pl-2"}>Obchodní jméno</td>
                        <td><InputField label={""} name={"Company.ObchodniJmeno"} isMandatory={true}/></td>
                    </tr>
                    <tr>
                        <td className={"pl-2"}>Fakturační adresa</td>
                        <td><InputField label={""} name={"Company.FakturacniAdresa"} isMandatory={true}/></td>
                    </tr>
                    <tr className={"bg-gray-100"}>
                        <td className={"pl-2"}>IČ</td>
                        <td><InputField label={""} name={"Company.IC"} isMandatory={true}/></td>
                    </tr>
                    <tr>
                        <td className={"pl-2"}>DIČ</td>
                        <td><InputField label={""} name={"Company.DIC"} isMandatory={false}/></td>
                    </tr>
                    <tr className={"bg-gray-100"}>
                        <td className={"pl-2"}>Kontaktní osoba</td>
                        <td><InputField label={""} name={"Company.KontaktniOsoba"} isMandatory={true}/></td>
                    </tr>
                    <tr>
                        <td className={"pl-2"}>Telefon</td>
                        <td><InputField label={""} name={"Company.Telefon"} isMandatory={true}/></td>
                    </tr>
                    <tr className={"bg-gray-100"}>
                        <td className={"pl-2"}>E-mail</td>
                        <td><InputField label={""} name={"Company.Email"} isMandatory={true}/></td>
                    </tr>
                    </tbody>

                </table>
            </div>
        )
    }

    const _plocha = (values) => {
        return (
            <div className={"bg-gray-100 mt-2 p-4 rounded"}>
                <h2 className={"font-bold"}>Vnitřní výstavní plocha s elektrickou přípojkou 2 kW včetně úklidu a
                    ostrahy</h2>
                <div className={"flex flex-row pt-4"}>
                    <div className={"flex w-1/4"}>
                        <InputField label={"Počet m2"} inputType={"number"} min={0} name={"inhouseMeter"}
                                    isMandatory={false}/>
                    </div>
                    <div className={"flex flex-col w-1/4 text-center"}>
                        <h2 className={"font-bold"}>Cena m<sup>2</sup> bez DPH</h2>
                        <h2><Currency type={"text"} price={3200}/></h2>
                    </div>
                    <div className={"flex flex-col w-1/4 text-center"}>
                        <h2 className={"font-bold"}>Celkem bez DPH</h2>
                        <h2>{values?.inhouseMeter ?
                            <Currency type={"text"} price={values.inhouseMeter * 3200}/> : "-"}</h2>
                    </div>
                    <div className={"flex w-1/4"}>
                        <InputField label={"Poznámka"} name={"inhouseNote"} isMandatory={false}/>
                    </div>
                </div>
            </div>
        )
    }

    const _plochaOut = (values) => {
        return (
            <div className={"bg-gray-100 mt-2 p-4 rounded"}>
                <h2 className={"font-bold"}>Venkovní výstavní plocha na prvním zámeckém nádvoří</h2>
                <div className={"flex flex-row pt-4"}>
                    <div className={"flex w-1/4"}>
                        <InputField label={"Počet m2"} inputType={"number"} min={0} name={"outhouseMeter"}
                                    isMandatory={false}/>
                    </div>
                    <div className={"flex flex-col w-1/4 text-center"}>
                        <h2 className={"font-bold"}>Cena m<sup>2</sup> bez DPH</h2>
                        <h2><Currency type={"text"} price={1600}/></h2>
                    </div>
                    <div className={"flex flex-col w-1/4 text-center"}>
                        <h2 className={"font-bold"}>Celkem bez DPH</h2>
                        <h2>{values?.outhouseMeter ?
                            <Currency type={"text"} price={values.outhouseMeter * 1600}/> : "-"}</h2>
                    </div>
                    <div className={"flex w-1/4"}>
                        <InputField label={"Poznámka"} name={"outhouseNote"} isMandatory={false}/>
                    </div>
                </div>
            </div>
        )
    }

    const _ticket = (values) => {
        return (
            <div className={"bg-gray-100 mt-2 p-4 rounded"}>
                <h2 className={"font-bold"}>Vstup na společenský večer 14.5.2024 ve Smetanově domě (delegáti mají
                    zahrnutý ve vložném)</h2>
                <div className={"flex flex-row pt-4"}>
                    <div className={"flex w-1/4"}>
                        <InputField label={"Počet vstupenek"} inputType={"number"} min={0} name={"ticketCount"}
                                    isMandatory={false}/>
                    </div>
                    <div className={"flex flex-col w-1/4 text-center"}>
                        <h2 className={"font-bold"}>Cena vstupenky bez DPH</h2>
                        <h2><Currency type={"text"} price={1600}/></h2>
                    </div>
                    <div className={"flex flex-col w-1/4 text-center"}>
                        <h2 className={"font-bold"}>Celkem bez DPH</h2>
                        <h2>{values?.ticketCount ?
                            <Currency type={"text"} price={values.ticketCount * 1600}/> : "-"}</h2>
                    </div>
                    <div className={"flex w-1/4"}>
                        <InputField label={"Poznámka"} name={"ticketNote"} isMandatory={false}/>
                    </div>
                </div>
            </div>
        )
    }

    const _partner = (values) => {
        return (
            <div className={"bg-gray-100 mt-2 p-4 rounded"}>
                <h2 className={"font-bold"}>
                    <CheckboxField name={"partnerIs"} isMandatory={false}
                                   label={<span>Mám zájem o partnerství konference v úrovni (popis služeb najdete <a target={"_blank"} href={"https://kdi.viaco.cz/wp-content/uploads/2023/11/KDI_nabidka_partneri_2024.pdf"}>zde</a>)</span>}/>
                </h2>
                <div className={"flex flex-row pt-4"}>
                    <div className={"flex w-1/3"}>
                        <RadioField name={"partner"} label={"Partner - 60 000,00 Kč"} id={"P_1"} value={"P"}
                                    isMandatory={values?.partnerIs}
                                    disabled={!values.partnerIs}/>
                    </div>
                    <div className={"flex flex-col w-1/3"}>
                        <RadioField name={"partner"} label={"Hlavní partner - 100 000,00 Kč"} value={"HP"} id={"P_2"}
                                    isMandatory={values?.partnerIs}
                                    disabled={!values.partnerIs}/>
                    </div>
                    <div className={"flex flex-col w-1/3"}>
                        <RadioField name={"partner"} label={"Premium partner - 140 000,00 Kč"} value={"PP"} id={"P-3"}
                                    isMandatory={values?.partnerIs}
                                    disabled={!values.partnerIs}/>
                    </div>

                </div>
            </div>
        )
    }

    const getPartner = (values) => {
        if (!values?.partner) return 0
        if (values.partner === "P") return 60000;
        if (values.partner === "HP") return 100000;
        if (values.partner === "PP") return 140000;
    }

    const getPrice = (values, type, count) => {
        if (!values[type]) return 0
        return values[type] * count
    }


    if (done) return (
        <div className={"text-center text-2xl"}>
            Formulář úspěšně odeslán. Děkujeme.
        </div>
    )

    return (
        <div className="container mx-auto text-base mt-2">
            <Form onSubmit={save} validate={(values)=>{
                let error ={}
                if(values.partnerIs && !values.partner){
                    error.partner = true
                }

                return error
            }} render={({handleSubmit, values}) => {
                return (
                    <>
                        <div className={"flex flex-col"}>
                            {tableinfouser()}
                            {_plocha(values)}
                            {_plochaOut(values)}
                            {_partner(values)}
                            {_ticket(values)}

                            <div className={"w-full mt-2 mb-2"}>
                                Výstavbu expozic ze systému Octanorm a jejich vybavení včetně grafiky zajišťuje náš
                                smluvní partner GENDAI, s.r.o., Výstaviště 67, 170 00 Praha 7, Andrea Bartošová,
                                a.bartosova@gendai.cz, +420 602 287 169.
                            </div>

                            {values?.inhouseMeter || values?.outhouseMeter || (values?.ticketCount && values.ticketCount !== 0) || (values?.partnerIs && values.partner) ?
                                <div className={"w-full mt-2 mb-2"}>
                                    <h2 className={"font-bold text-xl"}>Vybrané služby</h2>
                                    {values?.inhouseMeter && values.inhouseMeter !== 0 ?
                                        <div>Vnitřní výstavní plocha - {values?.inhouseMeter}m<sup>2</sup> - <Currency
                                            type={"text"} price={values.inhouseMeter * 3200}/></div> : null
                                    }
                                    {values?.outhouseMeter && values.outhouseMeter !== 0 ?
                                        <div>Venkovní výstavní plocha - {values?.outhouseMeter}m<sup>2</sup> - <Currency
                                            type={"text"} price={values.outhouseMeter * 1600}/></div> : null
                                    }

                                    {values.partnerIs && values.partner ?
                                        <div>Partnerství - <Currency type={"text"} price={getPartner(values)}/>
                                        </div> : null
                                    }
                                    {values?.ticketCount && values.ticketCount !== 0 ?
                                        <div>Počet vstupenek - {values?.ticketCount}ks - <Currency type={"text"}
                                                                                                   price={values.ticketCount * 1600}/>
                                        </div> : null
                                    }

                                    <h2 className={"font-bold text-xl"}>Celkem: {' '}
                                        <Currency type={"text"} price={
                                            getPrice(values, "inhouseMeter", 3200) +
                                            getPrice(values, "outhouseMeter", 1600) +
                                            getPrice(values, "ticketCount", 1600) +
                                            getPartner(values)}/>
                                    </h2>
                                </div>
                                : null}

                            <div>
                                <CheckboxField isMandatory={true} name={"term"}
                                               label={<span className={"font-bold"}>Souhlasím se zpracováním osobních údajů (<a
                                                   className={"text-blue underline"} target={"_blank"}
                                                   href={"https://v2-enigoo.s3.eu-central-1.amazonaws.com/kdi/VOP/VOP%20ENIGOO.pdf"}>PDF</a>)
                            </span>}/>
                            </div>
                        </div>
                        <div className={"mb-4"}>
                            <button disabled={loading} className={"text-white p-2 rounded mt-2"}
                                    style={{background: "#027fb2"}} onClick={handleSubmit}>
                                Odeslat objednávku
                            </button>
                        </div>
                    </>)
            }}/>
        </div>
    )
}

export default FormPage
