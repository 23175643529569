import React from "react";
import {NumericFormat} from 'react-number-format';
import PropTypes from "prop-types";

const Currency = (props) => {



    if (props.type === "input") {
        return (<NumericFormat thousandSeparator={" "}
                              decimalSeparator={","} fixedDecimalScale={true}
                              decimalScale={2} suffix={" Kč"}
                              value={props.price} displayType={props.type} className={props.selfClass}
                              disabled={props.disabled} placeholder={props.placeholder} autoComplete={"off"}
                              onValueChange={(value) => {
                                  // eslint-disable-next-line no-unused-expressions
                                  props.onChange ? props.onChange(value) : null
                                }
                              }
        />);
    } else {
        return (
            <NumericFormat thousandSeparator={" "}
                          decimalSeparator={","} fixedDecimalScale={true}
                          decimalScale={2} suffix={" Kč"}
                          value={props.price} displayType={props.type} className={props.selfClass}
            />
        );
    }
}

Currency.propTypes = {
    type: PropTypes.oneOf(["text", "input"]).isRequired,
    price: PropTypes.number.isRequired,
    selfClass: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func
}

export default Currency;
