import React from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";

const CheckboxField = (props) => {


    const errorMessage = (message) => {
        return <span className={"font-bold text-red-500 mr-1"}>{message}</span>;
    }

    const renderError = (_props) => {
        if (_props?.meta?.error && _props?.meta?.touched) {
            if (!_props.input.value) return errorMessage("Povinné pole")
        }
        return null;
    }


    return (
        <div className={"mr-1"}>
            <Field name={props.name}
                   validate={(value) => {
                       if (props.isMandatory) {
                           if (!value) return true
                       }
                   }}
                   disabled={props.disabled}
                   id={props.name + props.value}
                   render={(_props) => {
                       return (
                           <>
                               <input type={"checkbox"} id={props.name + props.value}
                                      onChange={(event) => {
                                          console.log(event.target.checked)
                                          _props.input.onChange(event.target.checked)
                                      }}/>
                               <label className={"ml-1 cursor-pointer"} htmlFor={props.name + props.value}>
                                   {props.label} {renderError(_props)}
                               </label>
                           </>
                       )
                   }}
            />

        </div>
    )
}
export default CheckboxField
