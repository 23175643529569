import React from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";

const RadioField = (props) => {

    const renderError = (_props) => {
        if (_props.meta.error && _props.meta.touched) {
            if (!_props.input.value || _props.input.value === "") return <div className={"text-red-500 font-bold text-base"}>Vyberte</div>;
        }
        return null;
    };

    return (
        <Field
            name={props.name}
            render={(_props) => {
                return (
                    <div className={"form-element w-full p-1 flex-col items-center justify-center"}>
                        <div className={"flex flex-row form-element-inline"}>
                            <input type="radio" id={props.id} value={props.value} disabled={props.disabled}
                                   name={props.name}
                                   onClick={() => _props.input.onChange(props.value)}
                            />
                            <label htmlFor={props.id} className={"cursor-pointer pl-2 "}> {props.label} </label>
                        </div>
                        <div className={"flex flex-col"}>
                            <div className={"text-sm pt-1"}> {renderError(_props)} </div>
                        </div>
                    </div>
                );
            }}
        />
    );
};

RadioField.defaultProps = {
    inputType: "text",
    disabled: false,
    isMandatory: false
};

RadioField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    inputStyle: PropTypes.object,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    showError: PropTypes.bool,
    isMandatory: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    dataTest: PropTypes.string,
    onEnter: PropTypes.func,
    id: PropTypes.string,
    inputType: PropTypes.oneOf(["text", "number", "password", "radio"]),
};

export default RadioField;
